export function userListTableHead () {
  return [
    {
      text: 'Id',
      align: 'start',
      value: 'id'
    },
    {
      text: 'Full name',
      value: 'fullName'
    },
    {
      text: 'E-Mail',
      value: 'email'
    },
    {
      text: 'Phone',
      value: 'phone'
    },
    {
      text: 'Points',
      value: 'points'
    },
    {
      text: 'Country',
      value: 'country'
    },
    {
      text: 'Created time',
      value: 'create_at'
    },
    {
      text: 'Status',
      value: 'status'
    },
    {
      text: 'Actions',
      value: 'actions',
      sortable: false
    }
  ]
}

export function courseListTableHead () {
  return [
    {
      text: 'Name',
      value: 'name'
    },
    {
      text: 'Picture',
      value: 'picture'
    },
    {
      text: 'Created time',
      value: 'createdAt'
    },
    {
      text: 'Type',
      value: 'type'
    },

    {
      text: 'Status',
      value: 'status',
      sort: (a, b) => {
        const nameA = a.data.title.toLowerCase()
        const nameB = b.data.title.toLowerCase()
        if (nameA > nameB) {
          return -1
        }
        if (nameA < nameB) {
          return 1
        }
        return 0
      }
    },
    {
      text: 'Actions',
      value: 'actions',
      sortable: false
    }
  ]
}

export function userCourseListTableHead () {
  return [
    {
      text: 'ID',
      value: 'id'
    },
    {
      text: 'Name',
      value: 'name',
      sort: (a, b) => {
        const nameA = a.data.name.toLowerCase()
        const nameB = b.data.name.toLowerCase()
        if (nameA > nameB) {
          return -1
        }
        if (nameA < nameB) {
          return 1
        }
        return 0
      }
    },
    {
      text: 'Package',
      value: 'package',
      sort: (a, b) => {
        const nameA = a.data.name.toLowerCase()
        const nameB = b.data.name.toLowerCase()
        if (nameA > nameB) {
          return -1
        }
        if (nameA < nameB) {
          return 1
        }
        return 0
      }
    },
    {
      text: 'Current progress',
      value: 'currentMaterial'
    },
    {
      text: 'Points',
      value: 'scored_points'
    },
    {
      text: 'Status',
      value: 'status'
    },
    {
      text: 'Actions',
      value: 'actions',
      sortable: false
    }
  ]
}

export function userCourseAccessListTableHead () {
	return [
		{
			text: 'ID',
			value: 'id'
		},
		{
			text: 'Name',
			value: 'name',
			sort: (a, b) => {
				const nameA = a.data.name.toLowerCase()
				const nameB = b.data.name.toLowerCase()
				if (nameA > nameB) {
					return -1
				}
				if (nameA < nameB) {
					return 1
				}
				return 0
			}
		},
		{
			text: 'Current progress',
			value: 'currentMaterial'
		},
		{
			text: 'Points',
			value: 'scored_points'
		},
		{
			text: 'Actions',
			value: 'actions',
			sortable: false
		}
	]
}

export function userCourseHomeWorkListTableHead () {
  return [
    {
      text: 'ID',
      value: 'id'
    },
    {
      text: 'Started at',
      value: 'startedAt'
    },
    {
      text: 'Module',
      value: 'module'
    },
    {
      text: 'Points',
      value: 'points'
    },
    {
      text: 'Type',
      value: 'type'
    },
    {
      text: 'Status',
      value: 'status'
    },
    {
      text: 'Actions',
      value: 'actions',
      sortable: false
    }
  ]
}

export function tariffsListTableHead () {
  return [
    {
      text: 'ID',
      value: 'id'
    },
    {
      text: 'Name',
      value: 'name'
    },
    {
      text: 'Days',
      value: 'days'
    },
    {
      text: 'Actions',
      value: 'actions',
      sortable: false
    }
  ]
}

export function employerList () {
  return [
    {
      name: '0',
      value: '0'
    },
    {
      name: '1-5',
      value: '1-5'
    },
    {
      name: '5-20',
      value: '5-20'
    },
    {
      name: '20-100',
      value: '20-100'
    },
    {
      name: '100+',
      value: '100+'
    }
  ]
}

export function genderList () {
  return [
    {
      name: this.$t('male'),
      value: 'male'
    },
    {
      name: this.$t('female'),
      value: 'female'
    }
  ]
}

export function activityList () {
  return [
    {
      name: this.$t('student'),
      value: 'student'
    },
    {
      name: this.$t('businessman'),
      value: 'businessman'
    },
    {
      name: this.$t('employee'),
      value: 'employee'
    },
    {
      name: this.$t('freelancer'),
      value: 'freelancer'
    },
    {
      name: this.$t('another'),
      value: 'another'
    }
  ]
}

export function userAdminsListTableHead () {
  return [
    {
      text: 'Id',
      align: 'start',
      value: 'id'
    },
    {
      text: 'Full name',
      value: 'fullName'
    },
    {
      text: 'E-Mail',
      value: 'email'
    },
    {
      text: 'Phone',
      value: 'phone'
    },
    {
      text: 'Country',
      value: 'country'
    },
    {
      text: 'Created time',
      value: 'create_at'
    },
    {
      text: 'Status',
      value: 'status'
    },
    {
      text: 'Actions',
      value: 'actions',
      sortable: false
    }
  ]
}
