import mixins from '@/mixins'
import course from '@/mixins/course'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { debounce } from 'lodash'
import { courseListTableHead } from '../../helpers'

export default {
  name: 'user-admins-home-works-courses',
  mixin: [mixins, course],
  data () {
    return {
      page: 1,
      pageCount: 20,
      itemsPerPage: 20,
      showRemoveDialog: false,
      removingItem: null
    }
  },
  computed: {
    ...mapGetters({
      list: 'courses/list',
      loading: 'courses/listLoading',
      lengthList: 'courses/listLength',
      skip: 'courses/skip',
      limit: 'courses/limit',
      setting: 'courses/setting',
      categories: 'courses/categories',
      types: 'courses/types',

      filterSearchInput: 'courses/filterSearchInput',
      filterCountries: 'courses/filterCountries',
      filterType: 'courses/filterType',
      filterStatus: 'courses/filterStatus',

      accessCountries: 'profile/accessCountries',
      removeListItemLoading: 'courses/listLoading'
    }),
    courseListTableHead
  },
  created () {
    this.fetchList({
      withCompletedWorks: true,
      userId: this.$route.params.id
    })
    if (this.list) {
      if (this.skip !== 0) {
        this.page = Math.round(this.skip / this.limit) + 1
      }
    }
    if (!this.categories) {
      this.fetchCategories()
    }
    if (!this.types) {
      this.fetchTypes()
    }
  },
  methods: {
    ...mapActions({
      fetchList: 'courses/GET_COURSES',
      fetchCategories: 'courses/COURSES_GET_CATEGORIES',
      fetchTypes: 'courses/COURSES_GET_TYPES'
    }),
    ...mapMutations({
      changeSkip: 'courses/COURSES_LIST_CHANGE_SKIP',
      changeFilter: 'courses/COURSES_FILTER_CHANGE'
    }),
    changeFilterLocal (value, type, isReset = false) {
      const obj = {
        type: type,
        value: value,
        isReset: isReset
      }
      this.changeFilter(obj)
      this.changeSkip(0)
      this.sendRequest()

      this.page = 1
    },
    changePagination (index) {
      this.changeSkip(this.limit * (index - 1))
      this.$vuetify.goTo(0)
      this.fetchList()
    },
    changeDialogDeleteItem (item) {
      this.showRemoveDialog = true
      this.removingItem = item
    },
    sendRequest: debounce(function () {
      this.fetchList()
    }, 1000)
  }
}
